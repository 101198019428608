function setSessionObject(key, data) {
    localStorage.setItem(key, JSON.stringify(data));
}

function getSessionObject(key) {
    return JSON.parse(localStorage.getItem(key));
}

export {
    setSessionObject,
    getSessionObject,
}
